import { Col, Container, Row } from 'react-bootstrap';
import MemberCard from '../components/MemberCard';

import eason from '../assets/member/eason.png';
import chris from '../assets/member/chris.jpeg';
import jeremy from '../assets/member/jeremy.png';
import warren from '../assets/member/warren.png';
import stanley from '../assets/member/stanley.png';
import bradley from '../assets/member/bradley.png';
import brian from '../assets/member/brian.jpg';
import josh from '../assets/member/josh.png';
import matthew from '../assets/member/matthew.png';
import majes from '../assets/member/majes.png';
import yoona from '../assets/member/yoona.jpeg';
import adrian from '../assets/member/adrian.png';
import jeff from '../assets/member/jeff.png';
import charlotte from '../assets/member/charlotte.png';
import david_chang from '../assets/member/david_chang.png';
import joe_chan from '../assets/member/joe_chan.png';
import joseph_liu from '../assets/member/joseph_liu.png';
import tom_schmidt from '../assets/member/tom_schmidt.jpg';
import ivan from '../assets/member/ivan.jpg';
import allen_peng from '../assets/member/allen_peng.jpeg';
import billy_so from '../assets/member/billy_so.jpeg';
import bun from '../assets/member/bun.jpeg';
import joseph_phua from '../assets/member/joseph_phua.png';
import ken from '../assets/member/ken.jpeg';
import ken2 from '../assets/member/ken2.jpeg';
import leo_cheng from '../assets/member/leo_cheng.jpeg';
import sam_liu from '../assets/member/sam_liu.jpg';
import willy_wu from '../assets/member/willy_wu.jpeg';
import wilson_huang from '../assets/member/wilson_huang.png';
import richie_jiaravanon from '../assets/member/richie_jiaravanon.png';
import hipo from '../assets/member/hipo.jpeg';
import vincent_chen from '../assets/member/vincent_chen.jpeg';

const MemberList = [
  {
    name: 'Chris',
    title: 'mrblock, Curve, PleasrDAO',
    twitter: 'https://twitter.com/mrblocktw',
    img: chris,
  },
  {
    name: 'Wilson Huang',
    title: 'XY.Finance, Galaxy.art, Steaker',
    twitter: 'https://twitter.com/wilsonhuangji',
    img: wilson_huang,
  },
  {
    name: 'MachiBigBrother',
    title: 'C.R.E.A.M. Finance, PleasrDAO',
    twitter: 'https://twitter.com/machibigbrother',
    img: jeff,
  },
  {
    name: 'Leo Cheng',
    title: 'C.R.E.A.M. Finance, PleasrDAO',
    twitter: 'https://twitter.com/leokcheng',
    img: leo_cheng,
  },
  {
    name: 'Richie Jiaravanon',
    title: 'Infinity Ventures Crypto (IVC), Fortune Magazine',
    twitter: 'https://twitter.com/richielychee',
    img: richie_jiaravanon,
  },
  {
    name: 'Tom Schmidt',
    title: 'Dragonfly Capital',
    twitter: 'https://twitter.com/tomhschmidt/',
    img: tom_schmidt,
  },
  {
    name: 'Brian Lu',
    title: 'Headline Asia, Infinity Ventures Crypto (IVC)',
    twitter: 'https://twitter.com/luball13',
    img: brian,
  },
  {
    name: 'Hipo',
    title: 'Venture Partner of Cherubic Ventures',
    twitter: '',
    img: hipo,
  },
  {
    name: 'David Chang',
    title: 'MindWorks',
    twitter: 'https://twitter.com/davidskch ',
    img: david_chang,
  },
  {
    name: 'Joe Chan',
    title: 'MindWorks',
    twitter: '',
    img: joe_chan,
  },
  {
    name: 'Joseph Liu',
    title: 'Founder of Matrixswap',
    twitter: 'https://twitter.com/TheJosephLiu',
    img: joseph_liu,
  },
  {
    name: 'Billy So',
    title: 'Soul Capital',
    twitter: 'https://twitter.com/degencaesar ',
    img: billy_so,
  },
  {
    name: 'Ivan Li',
    title: 'Red Building Capital',
    twitter: 'https://twitter.com/ivantok4',
    img: ivan,
  },
  {
    name: 'Josh Fraser',
    title: 'Origin Protocol',
    twitter: 'https://twitter.com/joshfraser',
    img: josh,
  },
  {
    name: 'Matthew Liu',
    title: 'Origin Protocol',
    twitter: 'https://twitter.com/matthewliu',
    img: matthew,
  },
  {
    name: 'Bun',
    title: 'C.R.E.A.M. Finance',
    twitter: '',
    img: bun,
  },
  {
    name: 'Eason Wu',
    title: 'C.R.E.A.M. Finance',
    twitter: 'https://twitter.com/eason_cream',
    img: eason,
  },
  {
    name: 'Jeremy Yang',
    title: 'C.R.E.A.M. Finance',
    twitter: 'https://twitter.com/JeremyYangHD',
    img: jeremy,
  },
  {
    name: 'Warren Cheng',
    title: 'C.R.E.A.M. Finance',
    twitter: 'https://twitter.com/devpaf',
    img: warren,
  },
  {
    name: 'Stanley',
    title: 'C.R.E.A.M. Finance',
    twitter: '',
    img: stanley,
  },
  {
    name: 'Bradley Zastrow',
    title: 'C.R.E.A.M. Finance',
    twitter: 'https://twitter.com/ZastrowBradley',
    img: bradley,
  },
  {
    name: 'Charlotte',
    title: 'C.R.E.A.M. Finance',
    twitter: '',
    img: charlotte,
  },
  {
    name: 'Joseph Phua',
    title: 'Turn Capital',
    twitter: '',
    img: joseph_phua,
  },
  {
    name: 'Alan Peng',
    title: 'Turn Capital',
    twitter: '',
    img: allen_peng,
  },
  {
    name: 'Leo Huang',
    title: 'Arcade.inc & Minty',
    twitter: '',
    img: '',
  },
  {
    name: 'Willy Wu',
    title: 'Arcade.inc & Minty',
    twitter: 'https://twitter.com/mintywilly',
    img: willy_wu,
  },
  {
    name: 'Sam Liu',
    title: 'SWAG',
    twitter: 'https://twitter.com/swat_sl',
    img: sam_liu,
  },
  {
    name: 'Yoona Chen',
    title: 'Binance',
    twitter: 'https://twitter.com/unac221',
    img: yoona,
  },
  {
    name: 'Majes Wei',
    title: 'Human Ape',
    twitter: 'https://twitter.com/majeswei',
    img: majes,
  },
  {
    name: 'Adrian Lai',
    title: 'Newman Capital',
    twitter: '',
    img: adrian,
  },
  {
    name: 'Vincent Chen',
    title: 'Apex Vantage Capital (AVC), WalkerDAO',
    twitter: '',
    img: vincent_chen,
  },
  {
    name: 'Ken',
    title: 'Mechanism Capital',
    twitter: '',
    img: ken2,
  },
]

const Member = () => {
  return (
    <Container fluid={true}>
      <Row className='g-3'>
        {MemberList.map((m, idx) => (
          <Col key={idx} xs={6} md={4} lg={3}>
            <MemberCard {...m} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Member;
