import styled from 'styled-components';
import useBreakpoint from '../hooks/useBreakpoint';

const Custody = () => {
  const isMd = useBreakpoint('md');
  const title = 'Our Project';
  const content = (
    <>
      Gradient is an independent type foundry and type consultancy with main focus on the production of high quality
      typefaces and type systems. Gradient strongly believes that the deconstruction of traditional typography combined
      with various type experiments, can re-imagine the future of contemporary typography. Gradient is run by Milos
      Mitrovic, and is based in the wonderful city of Bergen, Norway.
    </>
  )

  if (isMd) {
    return <DesktopCustody title={title} content={content} />
  } else {
    return <MobileCustody title={title} content={content} />
  }
}

const DesktopCustody = ({ title, content }) => {
  return (
    <Wrapper>
      <Left>{title}</Left>
      <Right>{content}</Right>
    </Wrapper>
  )
}

const MobileCustody = ({ title, content }) => {
  return (
    <MobileWrapper>
      <Top>{title}</Top>
      <Divider />
      <Bottom>{content}</Bottom>
    </MobileWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  font-size: 40px;
  justify-content: space-around;
`

const Left = styled.div`
  white-space: nowrap;
  padding: 0 40px;
`

const Right = styled.div`
  padding: 0 40px;
`

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  font-size: 20px;
`

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #3c3c3c;
  border-radius: 2px;
`

const Bottom = styled.div`
  margin-top: 16px;
  font-size: 18px;
`

export default Custody;
