import { Canvas } from '@react-three/fiber';
import { OrbitControls, useTexture } from '@react-three/drei';
import NFT from './components/NFT';
import { useMemo } from 'react';
import styled from 'styled-components';
import apes from './assets/nft/list';

const Landing = () => {
  const apePaths = apes.map((n) => {
    return `nft/${n}`;
  })
  const apeTextures = useTexture(apePaths);

  const randomPosition = (radius) => {
    let theta = 2 * Math.PI * Math.random();
    let phi = Math.acos(2 * Math.random() - 1);
    let dx = radius * Math.sin(phi) * Math.cos(theta);
    let dy = radius * Math.sin(phi) * Math.sin(theta);
    let dz = radius * Math.cos(phi);
    return [dx, dy, dz];
  }

  const nfts = useMemo(() => {
    return apeTextures.map((texture, idx) => (
      <NFT key={idx} texture={texture} position={randomPosition(10)} />
    ))
  }, [apeTextures]);

  return (
    <CanvasWrapper>
      <Canvas>
        {/*<Stats />*/}
        <ambientLight intensity={0.1} color={0xffffff} />
        <directionalLight position={[5, 5, 5]} />
        <OrbitControls autoRotate={true} autoRotateSpeed={5} enableRotate={false} enablePan={false} />
        {/*<mesh>*/}
        {/*  <sphereGeometry args={[0.5]} />*/}
        {/*  <meshStandardMaterial color={0xff0000} />*/}
        {/*</mesh>*/}
        {nfts}
      </Canvas>
    </CanvasWrapper>
  )
};

const CanvasWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
`;

export default Landing;
