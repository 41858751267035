import { useFrame } from '@react-three/fiber'
import { useRef } from 'react';

function NFT({ texture, position }) {
  const ref = useRef();

  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  let v = getRandomIntInclusive(200, 300);
  const disappearThreshold = 0.5;

  useFrame(() => {
    const p = ref.current.position;
    p.x += -p.x / v;
    p.y += -p.y / v;
    p.z += -p.z / v;

    v -= 1;

    const scaleFactor = 0.990;
    const [x, y, z] = ref.current.scale;
    // ref.current.scale.set(x * scaleFactor, y * scaleFactor, z * scaleFactor);

    if (p.x <= disappearThreshold && p.z <= disappearThreshold) {
      // if (p.x <= 1 && p.x >= -1 && p.z <= 1 && p.z >= -1) {
      [p.x, p.y, p.z] = position;
      v = getRandomIntInclusive(200, 500);
      // ref.current.scale.set(1, 1, 1);
    }
  });

  return (
    <sprite ref={ref} position={position}>
      <spriteMaterial map={texture} toneMapped={false} opacity={0.95}/>
    </sprite>
  )
}

export default NFT;
