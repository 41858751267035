import styled from 'styled-components';
import { ABOUT, HOME, MEMBER, PRESSKIT } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const SideNav = ({ show, setShow }) => {
  return (
    <Wrapper show={show}>
      <Cross icon={faTimes} onClick={() => setShow(false)} />
      <Items>
        <HomeItem>
          <a href={HOME.link}>HOME</a>
        </HomeItem>
        <Item>
          <a href={ABOUT.link}>ABOUT</a>
        </Item>
        <Item>
          <a href={MEMBER.link}>MEMBER</a>
        </Item>
        <Item>
          <a href={PRESSKIT.link}>PRESS KIT</a>
        </Item>
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  position: fixed;
  top: 0;
  left: ${(props) => props.show ? '0' : '-400px'};
  height: 100vh;
  max-width: 360px;
  width: 100vw;
  background-color: #211e1e;
  transition: all 300ms;
  z-index: 1;
`;

const Items = styled.ul`
  list-style-type: none;
  margin-top: 133px;
  margin-left: 60px;
`

const Item = styled.li`
  margin-top: 44px;
  font-size: 24px;
`

const HomeItem = styled(Item)`
  font-size: 40px;
`;

const Cross = styled(FontAwesomeIcon)`
  position: absolute;
  top: 22px;
  right: 22px;
  box-sizing: content-box;
  padding: 10px;
  cursor: pointer;
`;

export default SideNav;
