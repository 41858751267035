import { useMediaQuery } from 'react-responsive';

const useBreakpoint = (breakpoint) => {
  let query = '';

  switch (breakpoint) {
    case 'sm':
      query = '(min-width: 576px)'
      break;
    case 'md':
      query = '(min-width: 768px)'
      break;
    case 'lg':
      query = '(min-width: 992px)'
      break;
    case 'xl':
      query = '(min-width: 1200px)'
      break;
  }

  return useMediaQuery({ query })
}

export default useBreakpoint;
