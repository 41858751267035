import styled from 'styled-components';
import useBreakpoint from '../hooks/useBreakpoint';
import logoBBG from '../assets/logo-black-bg.svg';
import logoWBG from '../assets/logo-white-bg.svg';
import broomPng from '../assets/broom.png';

const PressKit = () => {
  const isLg = useBreakpoint('lg');
  const title = 'Who are we';
  const content = (
    <>
      Sweeping ain't much, but it's honest work...
    </>
  )

  if (isLg) {
    return <DesktopAbout title={title} content={content} />
  } else {
    return <MobileAbout title={title} content={content} />
  }
}

const DesktopAbout = ({ title, content }) => {
  return (
    <Wrapper>
      <Left className="d-flex flex-column align-items-center">
        <Logo src={logoBBG} />
        <div className="text-decoration-underline mt-5">
          <img src={broomPng} />
          <a href={"./logo-black-bg.zip"}>logo-black-bg.zip</a>
        </div>
      </Left>
      <Right className="d-flex flex-column align-items-center">
        <Logo src={logoWBG} style={{ backgroundColor: "white" }}/>
        <div className="text-decoration-underline mt-5">
          <img src={broomPng} />
          <a href={"./logo-white-bg.zip"}>logo-white-bg.zip</a>
        </div>
      </Right>
    </Wrapper>
  )
}

const MobileAbout = ({ title, content }) => {
  return (
    <MobileWrapper>
      <Top className="d-flex flex-column align-items-center">
        <MobileLogo src={logoBBG} />
        <div className="text-decoration-underline mt-5">
          <img src={broomPng} />
          <a href={"./logo-black-bg.zip"}>logo-black-bg.zip</a>
        </div>
      </Top>
      <Bottom className="d-flex flex-column align-items-center mt-5">
        <MobileLogo src={logoWBG} style={{ backgroundColor: "white" }}/>
        <div className="text-decoration-underline mt-5">
          <img src={broomPng} />
          <a href={"./logo-white-bg.zip"}>logo-white-bg.zip</a>
        </div>
      </Bottom>
    </MobileWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  font-size: 40px;
  justify-content: space-around;
`

const Logo = styled.img`
  width: 392px;
`;

const MobileLogo = styled.img`
  width: 320px;
`;

const Left = styled.div`
  padding: 0 40px;
`

const Right = styled.div`
  padding: 0 40px;
`

const MobileWrapper = styled.div`
  display: flex;
  padding: 0 40px;
  flex-direction: column;
`

const Top = styled.div`
  font-size: 20px;
`

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #3c3c3c;
  border-radius: 2px;
`

const Bottom = styled.div`
  margin-top: 16px;
  font-size: 18px;
`

export default PressKit;
