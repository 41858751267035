import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import broomAnimation from '../assets/broom.json';
import lottie from 'lottie-web';

const HomeLogo = () => {
  const ref = useRef();

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: broomAnimation,
    })
    return () => animation.destroy();
  }, [ref])

  return (
    <Wrapper>
      <Broom ref={ref} />
      <span>SweeperDAO</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: sonder,serif;
  font-size: 50px;
`

const Broom = styled.div`
  display: inline-block;
  width: 44px;
  height: 44px;
`

export default HomeLogo;
