import styled, { keyframes } from 'styled-components';

const glow = keyframes`
  from {
    text-shadow: 0 0 10px #ffffff88;
  }
  
  to {
    text-shadow: 0 0 10px #ffffff99, 0 0 11px #ffffff99;
  }
`

const GlowingText = styled.div`
  animation: ${glow} 1s linear infinite alternate;
`

export default GlowingText;
