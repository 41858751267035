import './App.css';
import Landing from './Landing';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import About from './pages/About';
import Member from './pages/Member';
import Page from './Page';
import Custody from './pages/Custody';
import PressKit from './pages/PressKit';

export const HOME = {
  text: 'HOME',
  link: '/',
}
export const ABOUT = {
  text: 'ABOUT',
  link: '/about',
}
export const MEMBER = {
  text: 'MEMBER',
  link: '/member',
}
export const PRESSKIT = {
  text: 'PRESS KIT',
  link: "/press-kit",
}

const routes = [
  {
    path: '/',
    name: 'HOME',
    children: <Landing />,
    links: [HOME, ABOUT, MEMBER, PRESSKIT],
  },
  {
    path: '/about',
    name: 'ABOUT',
    children: <About />,
    links: [ABOUT, HOME, MEMBER, PRESSKIT],
  },
  {
    path: '/member',
    name: 'MEMBER',
    children: <Member />,
    links: [MEMBER, ABOUT, HOME, PRESSKIT],
  },
  {
    path: '/press-kit',
    name: 'Press Kit',
    children: <PressKit />,
    links: [PRESSKIT, ABOUT, MEMBER, HOME],
  },
]

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          {routes.map((r, idx) => (
            <Route key={idx} path={r.path} exact>
              <Page links={r.links} route={r}>
                {r.children}
              </Page>
            </Route>
          ))}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
