import styled from 'styled-components';

const MemberCard = ({ name, title, img, twitter }) => {
  return (
    <Wrapper>
      {img ? (
        <Avatar src={img} />
      ) : (
        <Avatar />
      )}
      <Name>{name}</Name>
      <Title>{title}</Title>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #110f0f;
  min-height: 260px;
  height: 100%;
  border-radius: 5px;
`;

const Avatar = styled.img`
  margin-top: 30px;
  height: 125px;
  width: 125px;
`;

const Name = styled.div`
  margin-top: 20px;
  font-size: 18px;
`

const Title = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #9499ae;
`

export default MemberCard;
