const nefList = [
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_241.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_476.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_1362.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_1366.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_1391.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_1492.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_2968.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_3175.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_4883.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_4906.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_5194.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_5532.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_6630.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_7751.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_8417.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_8705.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_8993.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_23341.jpg',
  '0x60e4d786628fea6478f785a6d7e704777c86a7c6_28515.jpg',
  '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d_3156.jpg',
  '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d_3891.jpg',
  '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d_9062.jpg',
];

export default nefList;
