import styled from 'styled-components';
import useBreakpoint from '../hooks/useBreakpoint';

const About = () => {
  const isMd = useBreakpoint('md');
  const title = 'Who are we';
  const content = (
    <>
      Sweeping ain't much, but it's honest work.
    </>
  )

  if (isMd) {
    return <DesktopAbout title={title} content={content} />
  } else {
    return <MobileAbout title={title} content={content} />
  }
}

const DesktopAbout = ({ title, content }) => {
  return (
    <Wrapper>
      <Left>{title}</Left>
      <Right>{content}</Right>
    </Wrapper>
  )
}

const MobileAbout = ({ title, content }) => {
  return (
    <MobileWrapper>
      <Top>{title}</Top>
      <Divider />
      <Bottom>{content}</Bottom>
    </MobileWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  font-size: 40px;
  justify-content: space-around;
`

const Left = styled.div`
  white-space: nowrap;
  padding: 0 40px;
`

const Right = styled.div`
  padding: 0 40px;
`

const MobileWrapper = styled.div`
  display: flex;
  padding: 0 40px;
  flex-direction: column;
`

const Top = styled.div`
  font-size: 20px;
`

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: #3c3c3c;
  border-radius: 2px;
`

const Bottom = styled.div`
  margin-top: 16px;
  font-size: 18px;
`

export default About;
