import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import HomeLogo from './components/HomeLogo';
import SideNav from './components/SideNav';
import { useState } from 'react';
import useBreakpoint from './hooks/useBreakpoint';
import GlowingText from './components/GlowingText';

const Page = ({ links, route, children }) => {
  const isMd = useBreakpoint('md')

  if (isMd) {
    return <DesktopPage links={links} route={route} children={children} />
  } else {
    return <MobilePage links={links} route={route} children={children} />
  }
}

const DesktopPage = ({ links, route, children }) => {
  const isHome = route.name === 'HOME';
  const [showNav, setShowNav] = useState(false);

  return (
    <Wrapper>
      <SideNav show={showNav} setShow={setShowNav} />
      <Top>
        <Menu>
          <MenuIcon icon={faBars} onClick={() => setShowNav(true)} />
          <TopLeftLink onClick={() => setShowNav(true)}>{links[0].text}</TopLeftLink>
        </Menu>
        {isHome && <HomeLogo />}
        <Link href={links[1].link}>{links[1].text}</Link>
      </Top>
      <StyledContent>
        {children}
      </StyledContent>
      <Bottom>
        <Link href={links[2].link}>{links[2].text}</Link>
        <Link href={links[3].link}>{links[3].text}</Link>
      </Bottom>
    </Wrapper>
  )
}

const MobilePage = ({ links, route, children }) => {
  const [showNav, setShowNav] = useState(false);

  return (
    <Wrapper>
      <SideNav show={showNav} setShow={setShowNav} />
      <MobileTop>
        <MobileMenuIcon icon={faBars} onClick={() => setShowNav(true)} />
        <MobileTitle>
          {route.name}
        </MobileTitle>
      </MobileTop>
      <StyledMobileContent>
        {children}
      </StyledMobileContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const Link = styled.a`
  display: block;
  color: white;
  font-size: 24px;
  text-decoration: none;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  height: 95px;
  padding: 30px 40px 0 40px;
`;

const StyledContent = styled(Container)`
  padding: 60px 0 0 0;
  height: calc(100vh - 95px - 96px);
  overflow-y: scroll;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  padding: 30px 40px 30px 40px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
`;

const MenuIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  box-sizing: content-box;
  padding: 10px;
`

const TopLeftLink = styled(GlowingText)`
  font-size: 40px;
  margin-left: 12px;
  cursor: pointer;
  font-weight: 700;
`;

const MobileTop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 95px;
  padding: 30px 40px 0 40px;
`

const MobileMenuIcon = styled(MenuIcon)`
  position: absolute;
`;

const MobileTitle = styled(GlowingText)`
  margin: auto;
  font-size: 24px;
  font-weight: 700;
`;

const StyledMobileContent = styled(Container)`
  padding: 60px 0 0 0;
  height: calc(100vh - 95px);
  overflow-y: scroll;
`;

export default Page;
